import { useState, useMemo, useRef } from 'react';

export function useLess<S extends { [key: string]: any }>(
  factory: () => S,
  deps: any[] = []
) {
  const initial = useMemo(() => {
    const rich = factory();

    Object.keys(rich).forEach(function (key) {
      const color = nextColor();
      let intervalValue = rich[key];

      Object.defineProperty(rich, key, {
        get() {
          return intervalValue;
        },
        set(value) {
          intervalValue = value;
          ref.current = { ...ref.current, [key]: value };
          setState(ref.current);

          console.groupCollapsed(
            `%c set ${key} to ${value} ${new Date().toLocaleTimeString()} `,
            `background: ${color}; color: #000000; padding: 3px; width: 100%; display: block;`
          );
          console.trace();
          console.groupEnd();
        },
        enumerable: true
      });
    });

    return rich;
  }, deps);

  const ref = useRef<S>(initial);
  const [, setState] = useState<S>(initial);

  return initial;
}

function nextColor() {
  ++colorIndex;
  if (colorIndex >= colors.length) {
    colorIndex = 0;
  }
  return colors[colorIndex];
}

let colorIndex = -1;

const colors = [
  '#3366E6',
  '#99FF99',
  '#E6B3B3',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#33FFCC',
  '#991AFF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#CC9999',
  '#E6FF80',
  '#1AFF33',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#00B3E6',
  '#E6B333',
  '#E64D66',
  '#FF4D4D',
  '#6666FF'
];
